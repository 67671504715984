import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"

import { Footer } from "../../components/footer"
import { NavBar } from "../../components/header"
import Layout from "../../components/layout"
import Newsletter from "../../components/newsletter"
import Seo from "../../components/seo"

import BlogItem from "../../components/blogItem"
import BlogItemHeader from "../../components/blogItemHeader"

const Blog = ({ data }) => {
    const [page, setPage] = useState(1)

    const { coverImage, category, title, creator, createdDate, slug } = data.allContentfulBlogPost.nodes[0]
    const { totalCount } = data.allContentfulBlogPost

    const loadMore = page * 9 < totalCount

    return (
        <Layout>
            <Seo title="Blog" />

            <div className="top-gradient-container">
                <NavBar image />
                <StaticImage className="bg-contact-water blog d-none d-md-block" src="../../images/water1.png" alt="water" />
                <BlogItemHeader
                    coverImage={coverImage}
                    category={category}
                    title={title}
                    creator={creator}
                    createdDate={createdDate}
                    slug={slug}
                />
                <div className="container-xxl posts-list position-relative z-index-2">
                    <div className="row pt-4 pb-5">
                        <div className="col ps-inside pe-inside">
                            <div className=" row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4">
                                {data.allContentfulBlogPost.nodes.slice(0, page * 9).map(blog => (
                                    <BlogItem
                                        coverImage={blog.coverImage}
                                        category={blog.category}
                                        title={blog.title}
                                        slug={blog.slug}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    {loadMore && (
                        <div className="row">
                            <div className="col-12 d-flex align-items-center justify-content-center">
                                <button className="button fill light-background" onClick={() => setPage(p => p + 1)}>
                                    load more
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Newsletter />
            <Footer />
        </Layout>
    )
}

export default Blog

export const pageQuery = graphql`
    query PostsList {
        allContentfulBlogPost(sort: { order: ASC, fields: createdDate }) {
            totalCount
            nodes {
                title
                category
                id
                creator
                slug
                createdDate(formatString: "Do MMMM YYYY")
                coverImage {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    }
`
