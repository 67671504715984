import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"

const BlogItem = ({ coverImage, category, title, slug }) => {
    const [isHovered, setIsHovered] = useState(false)
    return (
        <Link className="position-relative" style={{ textDecoration: "none" }} to={`/blog/${slug}`}>
            <div className="col" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                {/* Example of card that is selected, uses classes yacht-selected-card & yacht-selected-card-body */}
                <div className={`card border-white h-100 position-relative ${isHovered ? "yacht-selected-card" : ""}`}>
                    <GatsbyImage
                        onMouseEnter={() => setIsHovered(true)}
                        className="card-img-top"
                        image={getImage(coverImage)}
                        alt="Yacht Image"
                    />
                    {/* Icon only to show on Booking process selection */}
                    {/* <FontAwesomeIcon className="yacht-info-icon" icon="fas-regular fa-circle-info " size="lg" /> */}
                    <div className={`card-body ${isHovered ? "yacht-selected-card-body" : ""}`}>
                        <div className="row g-0 align-items-center">
                            <div className="col-12">
                                <div className="category-badge blue mb-3">{category}</div>
                                <div className="col-12">
                                    <h4>{title}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default BlogItem
